var exports = {};

exports = function (hljs) {
  return {
    case_insensitive: true,
    contains: [hljs.HASH_COMMENT_MODE, // version of format and total amount of testcases
    {
      className: "meta",
      variants: [{
        begin: "^TAP version (\\d+)$"
      }, {
        begin: "^1\\.\\.(\\d+)$"
      }]
    }, // YAML block
    {
      begin: "(s+)?---$",
      end: "\\.\\.\\.$",
      subLanguage: "yaml",
      relevance: 0
    }, // testcase number
    {
      className: "number",
      begin: " (\\d+) "
    }, // testcase status and description
    {
      className: "symbol",
      variants: [{
        begin: "^ok"
      }, {
        begin: "^not ok"
      }]
    }]
  };
};

export default exports;